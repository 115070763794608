import form from 'react-validation/build/form';
import fetchRequest from '../auth/FetchInterceptorRequests';

const digitalizacijaCourseService = {
    // Course Management
    getCourses: () => {
        return fetchRequest({
            url: '/digitalizacija/courses',
            method: 'get'
        });
    },

    getCourse: (id) => {
        return fetchRequest({
            url: `/digitalizacija/courses/${id}`,
            method: 'get'
        });
    },

    createCourse: (data) => {
        const formData = new FormData();
         
        if (data.cover_image instanceof File) {
            formData.append('cover_image', data.cover_image);
          }

      

        formData.append('title', data.title);
        formData.append('description', data.description);
         formData.append('license_id', data.license_id);
        
        if (data.lang) {
            formData.append('lang', data.lang);
        }





        formData.append('active', data.active ? 1 : 0);

 
        return fetchRequest({
            url: '/digitalizacija/courses',
            method: 'post',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    updateCourse: (id, data) => {
        const formData = new FormData();
        
        if (data.cover_image instanceof File) {
            formData.append('cover_image', data.cover_image);
          }

      

        formData.append('title', data.title);
        formData.append('description', data.description);

        if (data.lang) {
            formData.append('lang', data.lang);
        }



        formData.append('active', data.active ? 1 : 0);

        formData.append('_method', 'PUT');

        return fetchRequest({
            url: `/digitalizacija/courses/${id}`,
            method: 'post',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    deleteCourse: (id) => {
        return fetchRequest({
            url: `/digitalizacija/courses/${id}`,
            method: 'delete'
        });
    },

    // Lesson Management
    getCourseLessons: (courseId) => {
        return fetchRequest({
            url: `/digitalizacija/courses/${courseId}/lessons`,
            method: 'get'
        });
    },

    createLesson: (courseId, data) => {
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            if (key === 'media_files' && data[key]) {
                data[key].forEach((file, index) => {
                    formData.append(`media_files[${index}]`, file);
                });
            } else {
                formData.append(key, data[key]);
            }
        });

        return fetchRequest({
            url: `/digitalizacija/courses/${courseId}/lessons`,
            method: 'post',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    updateLesson: (courseId, lessonId, data) => {
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            if (key === 'media_files' && data[key]) {
                data[key].forEach((file, index) => {
                    formData.append(`media_files[${index}]`, file);
                });
            } else {
                formData.append(key, data[key]);
            }
        });
        formData.append('_method', 'PUT');
        return fetchRequest({
            url: `/digitalizacija/courses/${courseId}/lessons/${lessonId}`,
            method: 'post',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    deleteLesson: (courseId, lessonId) => {
        return fetchRequest({
            url: `/digitalizacija/courses/${courseId}/lessons/${lessonId}`,
            method: 'delete'
        });
    }
};

export default digitalizacijaCourseService;