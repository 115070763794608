import React from 'react';
import { Card, Form, Select, Button, message } from 'antd';
import { useTranslation } from '../../../hooks/useTranslation';

const { Option } = Select;

const DigitalSettings = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    console.log('Settings values:', values);
    message.success(t('Settings saved successfully'));
  };

  return (
    <Card title={t('Digital Settings')}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          defaultLanguage: 'en'
        }}
      >
        <Form.Item
          name="defaultLanguage"
          label={t('Default Language')}
          rules={[{ required: true, message: t('Please select default language') }]}
        >
          <Select>
            <Option value="en">English</Option>
            <Option value="es">Spanish</Option>
            <Option value="fr">French</Option>
            <Option value="de">German</Option>
            <Option value="it">Italian</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t('Save Settings')}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default DigitalSettings;