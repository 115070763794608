const dev = {
  API_ENDPOINT_URL: "http://localhost:8000/api",
  STORAGE_ENDPOINT_URL: "http://localhost:8000",

};

const prod = {
  API_ENDPOINT_URL: "https://newims.tinkerlabs.org/api",
  STORAGE_ENDPOINT_URL: "https://newims.tinkerlabs.org",
};

const test = {
  API_ENDPOINT_URL: "/api",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      return dev;
  }
};

export const env = getEnv();
