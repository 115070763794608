import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Descriptions, Tabs, Button, Space, message, Tag } from 'antd';
import { TranslationOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from '../../../hooks/useTranslation';
import digitalCourseService from '../../../services/DigitalCourseService';

const { TabPane } = Tabs;

const DigitalCourseDetail = () => {
  const { courseId } = useParams();
  const { t } = useTranslation();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchCourse = async () => {
    try {
      const response = await digitalCourseService.getCourse(courseId);
      setCourse(response.data);
    } catch (error) {
      message.error(t('Failed to fetch course details'));
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchCourse();
  }, [courseId]);



  if (loading) {
    return null; // Or a loading spinner
  }

  if (!course) {
    return <div>{t('Course not found')}</div>;
  }

  return (
    <div>
      <Card
        title={course.title}
        extra={
          <Space>
            <Button icon={<EditOutlined />}>
              {t('Edit Course')}
            </Button>
            <Button icon={<TranslationOutlined />}>
              {t('Add Translation')}
            </Button>
          </Space>
        }
      >
        <Descriptions bordered column={2}>
          <Descriptions.Item label={t('Primary Language')}>
            {course.lang}
          </Descriptions.Item>
          <Descriptions.Item label={t('Available Languages')}>
            {course.available_languages?.map(lang => (
              <Tag key={lang} color="blue">{lang}</Tag>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label={t('Status')} span={2}>
            <Tag color={course.active ? 'success' : 'error'}>
              {course.active ? t('Active') : t('Inactive')}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label={t('Description')} span={2}>
            {course.description}
          </Descriptions.Item>
        </Descriptions>

        <Tabs defaultActiveKey="content" style={{ marginTop: 24 }}>
          <TabPane tab={t('Content')} key="content">
            {/* Content management will go here */}
            <div style={{ padding: 24 }}>
              {t('Content management coming soon')}
            </div>
          </TabPane>
          <TabPane tab={t('Translations')} key="translations">
            {/* Translations management will go here */}
            <div style={{ padding: 24 }}>
              {t('Translations management coming soon')}
            </div>
          </TabPane>
          <TabPane tab={t('Settings')} key="settings">
            {/* Course settings will go here */}
            <div style={{ padding: 24 }}>
              {t('Course settings coming soon')}
            </div>
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default DigitalCourseDetail;