import React from 'react';
import { Layout, Menu, Breadcrumb, Button, Select } from 'antd';
import { BookOutlined, ReadOutlined, TranslationOutlined, SettingOutlined } from '@ant-design/icons';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from '../../../hooks/useTranslation';

const { Header, Content, Sider } = Layout;
const { Option } = Select;

const AVAILABLE_LANGUAGES = [
  { code: 'en', name: 'English' },
  { code: 'es', name: 'Spanish' },
  { code: 'fr', name: 'French' },
  { code: 'de', name: 'German' },
  { code: 'it', name: 'Italian' },
];

const DigitalLayout = () => {
  const { t } = useTranslation();
  const location = useLocation();


  const renderBreadcrumb = () => {
    const paths = location.pathname.split('/').filter(path => path);
    return (
      <Breadcrumb className="mb-4">
        <Breadcrumb.Item>
          <Link to="/">{t('Home')}</Link>
        </Breadcrumb.Item>
        {paths.map((path, index) => (
          <Breadcrumb.Item key={path}>
            {index === paths.length - 1 ? (
              t(path.charAt(0).toUpperCase() + path.slice(1))
            ) : (
              <Link to={`/${paths.slice(0, index + 1).join('/')}`}>
                {t(path.charAt(0).toUpperCase() + path.slice(1))}
              </Link>
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
        <Content
          style={{
            padding: 24,
            margin: 0,
            background: '#fff',
            borderRadius: 4
          }}
        >
          {renderBreadcrumb()}
          <Outlet />
        </Content>
    </Layout>
  );
};

export default DigitalLayout;